import request from '@/utils/request'
import {domain} from '@/utils/config'

// 发送对话至引擎
export function sendDialogAI({ agentId, ...params }) {
  return request({
    url: `https://deportal.chinaeast2.cloudapp.chinacloudapi.cn/dialog-bot/api/${agentId}/Dialog`,
    method: 'post',
    params
  })
}

// 上报对话信息
export function sendDialogBE(params) {
  return request({
    url: `${domain}web/message/add`,
    method: 'post',
    params
  })
}

// 点赞和点踩
export function likeAnddislike(params) {
  return request({
    url: `${domain}h5/message/is_like`,
    method: 'post',
    params
  })
}

// 获取历史记录
export function getHistoryMessage(params) {
  return request({
    url: `${domain}web/message/search`,
    method: 'post',
    params
  })
}
